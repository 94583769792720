import React, {useEffect} from 'react';

const Reporting = ({match}) => {
    const id = match.params.id;

    switch (id) {
        case 'attendance-report':
            return (
                <section>
                    <div className="container-fluid">
                        {/*<h2>Attendance Report</h2>*/}
                        <iframe width="100%" height="800px"
                                title={id}
                                src="https://app.powerbi.com/reportEmbed?reportId=5c32b216-c71e-4844-8176-82b344ce932c&autoAuth=true&ctid=daf1138e-b295-43b4-b57f-fe07f137d8c5&config=eyJjbHVzdGVyVXJsIjoiaHR0cHM6Ly93YWJpLXVrLXNvdXRoLWItcHJpbWFyeS1yZWRpcmVjdC5hbmFseXNpcy53aW5kb3dzLm5ldC8ifQ%3D%3D%22&filterPaneEnabled=false&navContentPaneEnabled=false"
                                frameBorder="0" allowFullScreen={true}></iframe>
                    </div>
                </section>
            );

        case 'questions-photo-report':
            return (
                <section>
                    <div className="container-fluid">
                        {/*<h2>Photo Questions Report</h2>*/}
                        <iframe width="100%" height="800px"
                                title={id}
                                src="https://app.powerbi.com/reportEmbed?reportId=1e35fc0a-8555-4fa9-bfed-87a7c7d38a9b&autoAuth=true&ctid=daf1138e-b295-43b4-b57f-fe07f137d8c5&config=eyJjbHVzdGVyVXJsIjoiaHR0cHM6Ly93YWJpLXVrLXNvdXRoLWItcHJpbWFyeS1yZWRpcmVjdC5hbmFseXNpcy53aW5kb3dzLm5ldC8ifQ%3D%3D%22&filterPaneEnabled=false&amp;navContentPaneEnabled=false"
                                frameBorder="0" allowFullScreen={true}></iframe>
                    </div>
                </section>
            );

        case 'questions-multi-choice-report':
            return (
                <section>
                    <div className="container-fluid">
                        {/*<h2>Questions multi choice report</h2>*/}
                        <iframe width="100%" height="800px"
                                title={id}
                                src="https://app.powerbi.com/reportEmbed?reportId=fa8ef7b2-c039-453a-98d9-6c87a688f076&autoAuth=true&ctid=daf1138e-b295-43b4-b57f-fe07f137d8c5&config=eyJjbHVzdGVyVXJsIjoiaHR0cHM6Ly93YWJpLXVrLXNvdXRoLWItcHJpbWFyeS1yZWRpcmVjdC5hbmFseXNpcy53aW5kb3dzLm5ldC8ifQ%3D%3D%22&filterPaneEnabled=false&amp;navContentPaneEnabled=false"
                                frameBorder="0" allowFullScreen={true}></iframe>
                    </div>
                </section>);

        case 'merchandising-report':
            return (
                <section>
                    <div className="container-fluid">
                        {/*<h2>Merchandising report</h2>*/}
                        <iframe width="100%" height="800px"
                                title={id}
                                src="https://app.powerbi.com/reportEmbed?reportId=f7f694e4-f93f-4e12-8b15-26178c41d0b4&autoAuth=true&ctid=daf1138e-b295-43b4-b57f-fe07f137d8c5&config=eyJjbHVzdGVyVXJsIjoiaHR0cHM6Ly93YWJpLXVrLXNvdXRoLWItcHJpbWFyeS1yZWRpcmVjdC5hbmFseXNpcy53aW5kb3dzLm5ldC8ifQ%3D%3D%22&amp;filterPaneEnabled=false&amp;navContentPaneEnabled=false"
                                frameBorder="0" allowFullScreen={true}></iframe>
                    </div>
                </section>
            );

        case 'merchandising-photo-report':
            return (
                <section>
                    <div className="container-fluid">
                        {/*<h2>Merchandising photo report</h2>*/}
                        <iframe width="100%" height="800px"
                                title={id}
                                src="https://app.powerbi.com/reportEmbed?reportId=6e061a5b-bf7c-4435-b53d-9af91c583674&autoAuth=true&ctid=daf1138e-b295-43b4-b57f-fe07f137d8c5&config=eyJjbHVzdGVyVXJsIjoiaHR0cHM6Ly93YWJpLXVrLXNvdXRoLWItcHJpbWFyeS1yZWRpcmVjdC5hbmFseXNpcy53aW5kb3dzLm5ldC8ifQ%3D%3D%22&amp;filterPaneEnabled=false&amp;navContentPaneEnabled=false"
                                frameBorder="0" allowFullScreen={true}></iframe>
                    </div>
                </section>
            );

        default:
            return (<div className="container-fluid">Report <strong>{id}</strong> couldn't be found.</div>)
    }

};

export default Reporting;
