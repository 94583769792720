import React, {Component} from 'react';
import {
    Collapse,
    Container,
    Navbar,
    NavbarBrand,
    NavbarToggler,
    NavItem,
    NavLink,
    UncontrolledDropdown,
    DropdownToggle,
    DropdownMenu,
    DropdownItem
} from 'reactstrap';
import {withRouter} from 'react-router';
import {NavLink as RRNavLink} from 'react-router-dom';
import {LoginMenu} from './api-authorization/LoginMenu';
import RwwLogo from "./svg/RwwLogo";

class NavMenu extends Component {
    static displayName = NavMenu.name;

    constructor(props) {
        super(props);

        this.toggleNavbar = this.toggleNavbar.bind(this);
        this.state = {
            collapsed: true
        };
    }

    toggleNavbar() {
        this.setState({
            collapsed: !this.state.collapsed
        });
    }

    checkPath(pathname) {
        return window.location.pathname.indexOf(pathname) === 1
    }

    render() {
        return (
            <header className="bg-pink">
                <Navbar className="navbar-expand-md navbar-toggleable-md ng-white border-bottom box-shadow" light>
                    <Container fluid={true}>
                        <NavbarBrand className="pt-0" tag={RRNavLink} to="/">
                            <RwwLogo/>
                        </NavbarBrand>
                        <NavbarToggler onClick={this.toggleNavbar}
                                       className={`mr-2 p-0 border-0 ${(this.state.collapsed) ? 'closed' : 'open'}`}/>
                        <Collapse className="d-md-inline-flex" isOpen={!this.state.collapsed}
                                  navbar>
                            <ul className="navbar-nav flex-grow">
                                <NavItem>
                                    <NavLink tag={RRNavLink} activeClassName="active" className="" exact
                                             to="/">Home</NavLink>
                                </NavItem>
                                <UncontrolledDropdown nav inNavbar>
                                    <DropdownToggle id="nav_link_reporting" className={window.location.pathname.indexOf('reporting') === 1 ? 'active' : ''} nav caret>
                                        Reports
                                    </DropdownToggle>
                                    <DropdownMenu>
                                        <DropdownItem>
                                            <NavItem>
                                                <NavLink tag={RRNavLink} className=""
                                                         to="/reporting/attendance-report">Attendance Report</NavLink>
                                            </NavItem>
                                        </DropdownItem>
                                        <DropdownItem>
                                            <NavItem>
                                                <NavLink tag={RRNavLink} className=""
                                                         to="/reporting/questions-photo-report">Questions Photo
                                                    Report</NavLink>
                                            </NavItem>
                                        </DropdownItem>
                                        <DropdownItem>
                                            <NavItem>
                                                <NavLink tag={RRNavLink} className=""
                                                         to="/reporting/questions-multi-choice-report">Questions
                                                    Multi-choice Report</NavLink>
                                            </NavItem>
                                        </DropdownItem>
                                        <DropdownItem>
                                            <NavItem>
                                                <NavLink tag={RRNavLink} className=""
                                                         to="/reporting/merchandising-report">Merchandising
                                                    Report</NavLink>
                                            </NavItem>
                                        </DropdownItem>
                                        <DropdownItem>
                                            <NavItem>
                                                <NavLink tag={RRNavLink} className=""
                                                         to="/reporting/merchandising-photo-report">Merchandising Photo
                                                    Report</NavLink>
                                            </NavItem>
                                        </DropdownItem>
                                    </DropdownMenu>
                                </UncontrolledDropdown>
                                {/*<LoginMenu>*/}
                                {/*</LoginMenu>*/}
                            </ul>
                        </Collapse>
                    </Container>
                </Navbar>
            </header>
        );
    }
}

export default withRouter(NavMenu);